.settings-tab-sub-section {
    border: 1px #d7d7d7 solid;
    width: 75%;
}

.settings-tab-inline {
    display: flex;
}

.settings-tab-toggle {
    flex: 7%; 
}
@media (min-width: 575px) {
    .navbar-size {
        position: sticky;
        height: 100vh;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1020;
    }
}

.text-size {
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: .2px;
}

.logo-size {
    font-size: 20px;
    font-family: "Montserrat Medium", "Montserrat", "Montserrat", sans-serif;
    font-weight: 500;
    text-align: left;
    line-height: 24px;
}

.nav-shadow {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: white;
}

.nav-bottom-shadow {
    background-color: white;
}

.welcome-message {
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
    line-height: 12px;
}

.nav-link-navbar {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-size: 16px;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.nav-link-sub-navbar {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-size: 14px;
    text-decoration: none;
}

.bottom-link {
    position: absolute;
    bottom: 0px;
}

.fixed-bottom-navbar {
    background-color: white;
    position: fixed;
    bottom: 0px;
    width: 100%;
    overflow: visible;
    z-index: 99;
    box-shadow: rgb(0 0 0 / 15%) 1.95px -1.95px 2.6px;
    height: 64px;
}

.active {
    background-color: rgba(2,126,230, 30%);
    border-left: #027ee6 3px solid;
}

.navbar-elements {
    color: white;
}

.navbar-events-number {
    background-color: rgba(255, 255, 255, 0.8);
    color: #161e29;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 0px 6px 0px 6px;
    border-radius: 3px;
    float:right;
    margin-right: 10px;
}

.disabled-link {
    pointer-events: none;
}

.logo-section {
    color: white;
    margin-left: 1vw;
}

.active > a.nav-link.nav-link-sub-navbar {
    padding-left: calc(.5rem - 3px);
}

.active > a.nav-link.nav-link-navbar {
    padding-left: calc(.5rem - 3px);
}
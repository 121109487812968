.main-title {
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
    line-height: 24px;
    opacity: 0.7;
    font-family: "Montserrat Medium", "Montserrat", "Montserrat", sans-serif;
}

.table-events {
    padding: 1rem !important;
    border-bottom: 1px solid #ddd !important;
    word-wrap: break-word;
}

.table th {
    font-size: 12px;
    font-family: "Montserrat SemiBold", "Montserrat", sans-serif;
    font-weight: 600;
    color: #4d4d4d;
    text-align: left;
    line-height: 15px;
}

.eventsType {
    font-size: 12px;
    font-family: "Montserrat Medium", "Montserrat", sans-serif;
    font-weight: 500;
    color: #f59a23;
    text-align: left;
    line-height: 15px;
}

.table-text {
    font-family: "Montserrat Medium", "Montserrat", sans-serif;
    font-weight: 500;
    color: #4d4d4d;
    text-align: left;
    line-height: 15px;
    font-size: 12px;
    word-break: break-all;
}

.center-div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
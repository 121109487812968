.table-text {
    font-family: "Montserrat Medium", "Montserrat", sans-serif;
    font-weight: 500;
    color: #4d4d4d;
    text-align: left;
    line-height: 15px;
    font-size: 12px;
    word-break: break-all;
}

.modal-dialog,
.modal.show {
    margin: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.modal-header {
    border-bottom: 0px;
}

.modal-title-size {
    font-size: 20px;
    font-family: "Montserrat Medium", "Montserrat", "Montserrat", sans-serif;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
    line-height: 22px;
}

.modal-subtitle-size {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
    line-height: 21px;
}

.modal-footer {
    justify-content: space-between;
}

.table th {
    font-size: 12px;
    font-family: "Montserrat SemiBold", "Montserrat", sans-serif;
    font-weight: 600;
    color: #4d4d4d;
    text-align: left;
    line-height: 15px;
    margin-left: 1.3rem;
}

.table-source {
    word-wrap: break-word;
}

.center-icon {
    position: absolute;
    right: 0.5rem;
    top: 19px;
}

.center-div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 25vh; 
}
.left-side {
    background-image: url('../images/login.jpeg');
    background-size: cover;
    height: 100vh;
}

.divider {
    width: 240px;
    font-size: 10px;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }
  
.divider::before, .divider::after {
    flex: 1;
    content: '';
    padding: 0.05rem;
    background-color: rgba(0, 0, 0, 0.13);
    margin: 5px;
}